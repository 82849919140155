const CheckBoxSvg = ({ checked = false, width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {checked ? (
        <g id="state=selected" clip-path="url(#clip0_80_5109)">
          <path
            id="ic"
            fillRule="evenodd"
            clip-rule="evenodd"
            d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM9.45549 15.3443L5.96135 12.2818C5.75471 12.1556 5.23412 12.005 4.80484 12.4122C4.37556 12.8195 4.53408 13.2921 4.667 13.4774L8.78042 17.0823C8.97305 17.2933 9.5179 17.5886 10.1563 17.0823L19.0115 9.34896C19.2507 9.16797 19.6155 8.66146 19.1563 8.0869C18.7578 7.58826 18.0912 7.80129 17.8434 8.00041L9.45549 15.3443Z"
            fill="#D12D34"
          />
        </g>
      ) : (
        <g id="state=unselect" clip-path="url(#clip0_80_5110)">
          <path
            id="ic"
            d="M3.66667 0C1.65348 0 0 1.65348 0 3.66667V20.3333C0 22.3465 1.65348 24 3.66667 24H20.3333C22.3465 24 24 22.3465 24 20.3333V3.66667C24 1.65348 22.3465 0 20.3333 0H3.66667ZM3.66667 2H20.3333C21.2655 2 22 2.73452 22 3.66667V20.3333C22 21.2655 21.2655 22 20.3333 22H3.66667C2.73452 22 2 21.2655 2 20.3333V3.66667C2 2.73452 2.73452 2 3.66667 2Z"
            fill="#9B99AE"
          />
        </g>
      )}

      <defs>
        <clipPath id="clip0_80_5110">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>

      <defs>
        <clipPath id="clip0_80_5109">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckBoxSvg;
