import { LocationData } from '@/Components/Home/HotLocations';
import ArrowUpSvg from '@/Components/SVG/ArrowUpSvg';
import styles from '@/styles/Cards/Location/locationcard.module.scss';
import {
  CalculatePercentChange,
  NumberFormat,
  convertNumberToPrice,
} from '@/utils';
import { MobileContext } from '@/utils/DetectMobileContext';
import { ImageToBase64, LoaderShimmer } from '@/utils/imageHelpers';
import Image from 'next/image';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
interface localityCardDTO {
  data: LocationData;
}
const LocationCard = ({ data }: localityCardDTO) => {
  const tabOptions = [
    'Sales vol. in 2023',
    'Sales value in 2023',
    'Avg. Property Price',
    'Avg. Price per Sqft',
  ];
  const [tab, settab] = useState('Avg. Price per Sqft');

  const [percentChangeSqft, setpercentChangeSqft] = useState<null | string>(
    null
  );
  const [percentChangeTxn, setpercentChangeTxn] = useState<null | string>(null);
  const [percentChangeSales, setpercentChangeSales] = useState<null | string>(
    null
  );
  const [percentChangeAvg, setpercentChangeAvg] = useState<null | string>(null);

  useEffect(() => {
    if (data) {
      setpercentChangeSales(
        CalculatePercentChange(data.previousTotalVolume, data.totalVolume)
      );
      setpercentChangeSqft(
        CalculatePercentChange(data.previousTotalSqft, data.totalSqft)
      );
      setpercentChangeTxn(
        CalculatePercentChange(
          data.prevYearTransactions,
          data.totalTransactions
        )
      );
      setpercentChangeAvg(
        CalculatePercentChange(data.previousAvgPrice, data.avgPrice)
      );
    }

    return () => {
      setpercentChangeSales(null);
      setpercentChangeSqft(null);
      setpercentChangeTxn(null);
      setpercentChangeAvg(null);
    };
  }, [data]);
  const { isMobile } = useContext(MobileContext);
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardContainer_left}>
        <Image
          src={data?.overview?.locationImage[0]}
          placeholder={`data:image/svg+xml;base64,${ImageToBase64(
            LoaderShimmer(700, 475)
          )}`}
          alt={data._id || 'location'}
          width={700}
          height={600}
        />
        <section className={styles.cardContainer_left_overlay}>
          <div className={styles.cardContainer_left_overlay_top}>
            <span>
              Hot Locations: <span>Location-based Price Insights</span>
            </span>
          </div>
          <div className={styles.cardContainer_left_overlay_bot}>
            <Link href={`/projects?keyword=${data._id}&keyType=location`}>
              <span>
                Tap here to Compare all Property Projects within {data._id}
              </span>
            </Link>
            <aside>
              <ArrowUpSvg
                color="#fff"
                width={isMobile ? 18 : 22}
                height={isMobile ? 18 : 22}
              />
            </aside>
          </div>
        </section>
      </div>
      <div className={styles.cardContainer_right}>
        <header className={styles.cardContainer_right_header}>
          <h2>{data._id}</h2>
        </header>
        <main className={styles.cardContainer_right_body}>
          {tab === 'Avg. Price per Sqft' ? (
            <>
              <h3>
                AED {data.totalSqft.toFixed(2)}/ <span>sqft</span>
              </h3>
              <span>Average Price/ sqft</span>
              {percentChangeSqft && (
                <button
                  className={
                    styles[
                      percentChangeSqft?.includes('+')
                        ? 'cardContainer_right_body_upbtn'
                        : 'cardContainer_right_body_downbtn'
                    ]
                  }
                >
                  {percentChangeSqft}{' '}
                  {percentChangeSqft?.includes('+') ? ' up ' : ' down '} vs 2022
                </button>
              )}
            </>
          ) : tab === 'Avg. Property Price' ? (
            <>
              <h3>{convertNumberToPrice(data.avgPrice)}</h3>
              <span>Average Price</span>
              {percentChangeAvg && (
                <button
                  className={
                    styles[
                      percentChangeAvg?.includes('+')
                        ? 'cardContainer_right_body_upbtn'
                        : 'cardContainer_right_body_downbtn'
                    ]
                  }
                >
                  {percentChangeAvg}{' '}
                  {percentChangeAvg?.includes('+') ? ' up ' : ' down '} vs 2022
                </button>
              )}
            </>
          ) : tab === 'Sales vol. in 2023' ? (
            <>
              <h3>
                {NumberFormat(data.totalTransactions)} <span>TXN</span>
              </h3>
              <span>Total Txn 2023</span>
              {percentChangeTxn && (
                <button
                  className={
                    styles[
                      percentChangeTxn?.includes('+')
                        ? 'cardContainer_right_body_upbtn'
                        : 'cardContainer_right_body_downbtn'
                    ]
                  }
                >
                  {percentChangeTxn}{' '}
                  {percentChangeTxn?.includes('+') ? ' up ' : ' down '} vs 2022
                </button>
              )}
            </>
          ) : tab === 'Sales value in 2023' ? (
            <>
              <h3>{convertNumberToPrice(data.totalVolume)}</h3>
              <span>Total Sales 2023</span>
              {percentChangeSales && (
                <button
                  className={
                    styles[
                      percentChangeSales?.includes('+')
                        ? 'cardContainer_right_body_upbtn'
                        : 'cardContainer_right_body_downbtn'
                    ]
                  }
                >
                  {percentChangeSales}{' '}
                  {percentChangeSales?.includes('+') ? ' up ' : ' down '} vs
                  2022
                </button>
              )}
            </>
          ) : (
            <></>
          )}
        </main>
        <footer className={styles.cardContainer_right_foot}>
          {tabOptions.map((item: string, index: number) => (
            <button
              key={item + String(index)}
              className={
                styles[tab === item ? 'cardContainer_right_foot_active' : '']
              }
              onClick={() => settab(item)}
            >
              {item}
            </button>
          ))}
        </footer>
      </div>
    </div>
  );
};

export default LocationCard;
