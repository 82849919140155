import { FunctionComponent, useEffect, useState } from 'react';
import SlideRightSvg from '../SVG/SlideRightSvg';
import { ButtonProps } from './ArrowBack';

const ArrowForward: FunctionComponent<ButtonProps> = ({
  state = 'active',
  color = '#F0F1F4',
  onClick,
}) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);
  return (
    <div
      onClick={(e) => {
        if (state === 'active') {
          //@ts-ignore
          const rect = e.target.getBoundingClientRect();
          setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
          onClick && onClick(e);
        }
      }}
      className={`customSwiperButton customSwiperButton-right ${
        'customSwiperButton' + state
      }`}
    >
      {isRippling ? (
        <span
          className="swiperRipple"
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      ) : (
        ''
      )}
      <SlideRightSvg
        disabled={state === 'active' ? false : true}
        color={color}
      />
    </div>
  );
};

export default ArrowForward;
