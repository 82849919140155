import { create } from 'zustand';

type SelectedType =
  | 'Sold Property Records'
  | 'Property Rental Records'
  | 'Property Unit History'
  | 'Return on Investment Search'
  | 'Price Insights'
  | 'Buy'
  | 'Rent'
  | 'Agent Listings'
  | 'Agent Listings'
  | 'Agent Requirements'
  | 'Property Rental Records';

interface SearchTypeOption {
  selectedType: SelectedType;
  searchKeyword: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedType: (newType: SelectedType) => void;
  // eslint-disable-next-line no-unused-vars
  setSearchKeyword: (newKeyword: string) => void;
  resetSelectedType: () => void;
}
const useSearchTypeStore = create<SearchTypeOption>((set) => ({
  selectedType: 'Price Insights',

  setSelectedType: (newType: SelectedType) => set({ selectedType: newType }),
  resetSelectedType: () => set({ selectedType: 'Price Insights' }),
  searchKeyword: '',
  setSearchKeyword: (newKeyword: string) => set({ searchKeyword: newKeyword }),
}));

interface UserDetails {
  accountType: string;
  firstName: string;
  lastName: string;
  email: string;
  brn?: string;
  phoneNumber: string;
  budgetExpertise: [];
  languages: string[];
  budget: string;
  location: [];
  password: string;
  confirmPassword: string;
  whatsappNotify: boolean;
  emailNotify: boolean;
  newProperty: boolean;
  newRequirements: boolean;
  dailyTransaction: { personal: boolean; dld: boolean };
  weeklyReport: { personal: boolean; dld: boolean };
  monthlyReport: { personal: boolean; dld: boolean };
}
interface CompanyDetails {
  accountType: string;
  name: string;
  tradeLicenseNumber: string;
  email: string;
  crmName: string;
  crmUrl: string;
  phoneNumber: string;
}
interface UserDetailsStore {
  userDetails: UserDetails;
  setUserDetails: (newUserDetails: UserDetails) => void;
}
interface CompanyDetailsStore {
  companyDetails: CompanyDetails;
  setCompanyDetails: (newCompanYdetails: CompanyDetails) => void;
}

const useUserDetailsStore = create<UserDetailsStore>((set) => ({
  userDetails: {
    accountType: 'Individual',
    firstName: '',
    lastName: '',
    email: '',
    brn: '',
    phoneNumber: '+971',
    budgetExpertise: [],
    budget: '',
    password: '',
    confirmPassword: '',
    location: [],
    languages: [],

    whatsappNotify: true,
    emailNotify: true,
    newProperty: true,
    newRequirements: true,
    dailyTransaction: { personal: true, dld: false },
    weeklyReport: { personal: false, dld: false },
    monthlyReport: { personal: false, dld: false },
  },
  setUserDetails: (newUserDetails: UserDetails) =>
    set(() => ({ userDetails: newUserDetails })),
}));

const useCompanyDetailsStore = create<CompanyDetailsStore>((set) => ({
  companyDetails: {
    name: '',
    email: '',
    brn: undefined,
    phoneNumber: '+971',
    accountType: 'Company',
    tradeLicenseNumber: '',
    crmName: '',
    crmUrl: '',
  },
  setCompanyDetails: (newCompanyDetails: CompanyDetails) =>
    set(() => ({ companyDetails: newCompanyDetails })),
}));

interface searchKeyStore {
  searchKey: string;
  setSearchKey: (search: string) => void;
}
const useSearchKeyEstimationStore = create<searchKeyStore>((set) => ({
  searchKey: '',
  setSearchKey: (newSearchKey: string) => set({ searchKey: newSearchKey }),
}));
export {
  useCompanyDetailsStore,
  useSearchKeyEstimationStore,
  useSearchTypeStore,
  useUserDetailsStore,
};
