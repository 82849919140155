const ArrowUpSvg = ({ color = '#363352', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon/arrow up">
        <path
          id="Vector"
          d="M12.0029 21.3764C11.7045 21.3764 11.4184 21.2578 11.2074 21.0469C10.9964 20.8359 10.8779 20.5497 10.8779 20.2514L10.8779 6.47013L6.04883 11.3001C5.83748 11.5115 5.55084 11.6302 5.25195 11.6302C4.95307 11.6302 4.66642 11.5115 4.45508 11.3001C4.24373 11.0888 4.125 10.8021 4.125 10.5033C4.125 10.2044 4.24373 9.91772 4.45508 9.70638L11.2051 2.95638C11.3096 2.8515 11.4338 2.76828 11.5705 2.7115C11.7073 2.65472 11.8539 2.62549 12.002 2.62549C12.15 2.62549 12.2966 2.65472 12.4334 2.7115C12.5701 2.76828 12.6943 2.8515 12.7988 2.95638L19.5488 9.70638C19.6535 9.81102 19.7365 9.93526 19.7931 10.072C19.8498 10.2087 19.8789 10.3553 19.8789 10.5033C19.8789 10.6512 19.8498 10.7978 19.7931 10.9345C19.7365 11.0712 19.6535 11.1955 19.5488 11.3001C19.4442 11.4048 19.3199 11.4878 19.1832 11.5444C19.0465 11.6011 18.8999 11.6302 18.752 11.6302C18.604 11.6302 18.4574 11.6011 18.3207 11.5444C18.184 11.4878 18.0597 11.4048 17.9551 11.3001L13.1279 6.47013V20.2514C13.1279 20.5497 13.0094 20.8359 12.7984 21.0469C12.5874 21.2578 12.3013 21.3764 12.0029 21.3764Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ArrowUpSvg;
