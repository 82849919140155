const WhatsappIconSvg = ({ color = '#363352', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icpn/wmessage">
        <path
          d="M12.0017 2.625C10.3696 2.6247 8.76572 3.05048 7.34866 3.86024C5.9316 4.67 4.75049 5.83567 3.92215 7.24196C3.09382 8.64824 2.64696 10.2464 2.62579 11.8784C2.60462 13.5104 3.00986 15.1196 3.80143 16.5469L2.6858 19.8928C2.6197 20.091 2.61011 20.3037 2.6581 20.5071C2.70609 20.7105 2.80978 20.8965 2.95753 21.0442C3.10528 21.192 3.29126 21.2956 3.49463 21.3436C3.69799 21.3916 3.91071 21.382 4.10893 21.3159L7.45487 20.2003C8.70648 20.8938 10.1002 21.2916 11.5293 21.3632C12.9585 21.4349 14.385 21.1784 15.6997 20.6135C17.0143 20.0486 18.1823 19.1903 19.114 18.1043C20.0457 17.0183 20.7164 15.7334 21.0748 14.3481C21.4332 12.9628 21.4697 11.5138 21.1816 10.1122C20.8934 8.71065 20.2883 7.3936 19.4125 6.26202C18.5367 5.13044 17.4135 4.21434 16.129 3.58392C14.8445 2.9535 13.4326 2.62548 12.0017 2.625ZM12.0017 20.625C10.4853 20.6255 8.99555 20.2261 7.68268 19.4672C7.62563 19.4343 7.56101 19.4169 7.49518 19.4166C7.45468 19.4165 7.41445 19.4232 7.37612 19.4363L3.87174 20.6044C3.80567 20.6264 3.73476 20.6296 3.66698 20.6136C3.59919 20.5976 3.53719 20.5631 3.48794 20.5138C3.43869 20.4646 3.40413 20.4026 3.38813 20.3348C3.37213 20.267 3.37533 20.1961 3.39737 20.13L4.56549 16.6256C4.58234 16.5752 4.5883 16.5219 4.58296 16.469C4.57763 16.4162 4.56113 16.3651 4.53455 16.3191C3.58365 14.6754 3.20176 12.7638 3.44813 10.881C3.6945 8.99811 4.55536 7.24917 5.89715 5.90549C7.23894 4.56181 8.98667 3.6985 10.8692 3.44948C12.7517 3.20047 14.6638 3.57967 16.3088 4.52826C17.9538 5.47685 19.2398 6.94181 19.9672 8.69586C20.6947 10.4499 20.823 12.395 20.3321 14.2294C19.8413 16.0638 18.7588 17.6849 17.2526 18.8413C15.7464 19.9977 13.9007 20.6247 12.0017 20.625Z"
          fill={color}
          stroke={color}
          strokeWidth="0.3"
        />
        <path
          d="M16.1022 13.4826C15.8813 13.3701 14.7844 12.8317 14.5795 12.7594C14.3746 12.683 14.2259 12.6469 14.0772 12.8719C13.9286 13.0969 13.5027 13.5951 13.3701 13.7478C13.2415 13.8964 13.1089 13.9165 12.8879 13.804C11.5781 13.1491 10.7183 12.6348 9.85446 11.1522C9.62545 10.7585 10.0835 10.7866 10.5094 9.93482C10.5817 9.78616 10.5455 9.65759 10.4893 9.54509C10.433 9.43259 9.98705 8.33571 9.80223 7.88973C9.62143 7.4558 9.43661 7.51607 9.3 7.50804C9.17143 7.5 9.02277 7.5 8.87411 7.5C8.72545 7.5 8.48437 7.55625 8.27946 7.77723C8.07455 8.00223 7.5 8.54062 7.5 9.6375C7.5 10.7344 8.29955 11.7951 8.40804 11.9438C8.52054 12.0924 9.97902 14.3424 12.217 15.3107C13.6312 15.9214 14.1857 15.9737 14.8929 15.8692C15.3228 15.8049 16.2107 15.3308 16.3955 14.8085C16.5804 14.2862 16.5804 13.8402 16.5241 13.7478C16.4719 13.6473 16.3232 13.5911 16.1022 13.4826Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default WhatsappIconSvg;
