import { fetcher } from '@/services/fetcher';
import styles from '@/styles/SideComp/sidecomponents.module.scss';
import { NumberFormat } from '@/utils';
import { MobileContext } from '@/utils/DetectMobileContext';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as React from 'react';
import useSWR from 'swr';
import DotLoader from '../Loaders/DotLoader';
import DiagonalArrowSvg2 from '../SVG/DiagonalArrowSvg2';

interface sideGolden {
  OpenAllLocations: boolean;
  setOpenAllLocations: (open: boolean) => void;
  categoryType: string;
}
export default function SideLocationsOnly({
  OpenAllLocations,
  setOpenAllLocations,
  categoryType,
}: sideGolden) {
  const { data, isLoading } = useSWR(
    `/transaction/locations-all?location=${categoryType}`,
    fetcher
  );
  const { isMobile } = React.useContext(MobileContext);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpenAllLocations(open);
    };
  const router = useRouter();

  const list = () => (
    <Box
      sx={{
        width: isMobile ? '100%' : '400px',
        padding: '20px',
        overflowX: 'hidden',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={styles.sidebarMain_heading}>
        <span style={{ fontWeight: 'bold' }}>Localities In Dubai</span>{' '}
        <KeyboardBackspaceIcon onClick={() => toggleDrawer(false)} />
        <ClearIcon onClick={() => toggleDrawer(false)} />
      </div>
      <Divider />
      <List>
        <div className={styles.sidebarMain_cardCollection}>
          {(!data || isLoading) && <DotLoader />}
          {data &&
            data.map((location: any, index: number) => (
              <>
                {/* <div
                style={{ width: '100px', height: '100px' }}
                className={styles.sidebarMain_lcards_left}
              >
                <Image
                  width={700}
                  height={700}
                  src={
                    location.overviewdata &&
                    location.overviewdata.locationImage[0] &&
                    location.overviewdata.locationImage[0] !== ''
                      ? location.overviewdata.locationImage[
                          Math.floor(
                            Math.random() *
                              location.overviewdata?.locationImage.length
                          )
                        ]
                      : 'https://static.99acres.com/images/locationREI/defaultSquareLocality.png'
                  }
                  alt={location._id}
                />
              </div> */}
                <div
                  className={styles.sidebarMain_cardCollection_card}
                  key={location._id}
                >
                  <Image
                    width={700}
                    height={700}
                    src={
                      location.overviewdata &&
                      location.overviewdata.locationImage[0] &&
                      location.overviewdata.locationImage[0] !== ''
                        ? location.overviewdata.locationImage[
                            Math.floor(
                              Math.random() *
                                location.overviewdata?.locationImage.length
                            )
                          ]
                        : 'https://static.99acres.com/images/locationREI/defaultSquareLocality.png'
                    }
                    alt={location._id}
                  />
                  <div
                    className={styles.sidebarMain_cardCollection_card_content}
                  >
                    <h4>{location._id}</h4>
                    <div
                      className={
                        styles.sidebarMain_cardCollection_card_content_other
                      }
                    >
                      <button
                        onClick={() =>
                          router.push(
                            `/projects?keyword=${location._id}&keyType=location`
                          )
                        }
                      >
                        Total Projects &nbsp;
                        <span>
                          {location.projects && (
                            <>({NumberFormat(location.projects)})</>
                          )}
                        </span>
                        <DiagonalArrowSvg2 width={10} height={10} />
                      </button>
                      <div
                        className={
                          styles.sidebarMain_cardCollection_card_content_other_sales
                        }
                      >
                        <span>Total Sales Value: 123AED</span>
                        <span>Total Sales Volume: 123AED</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          {/* <p
          onClick={() =>
            router.push(`/projects?keyword=${location._id}&keyType=location`)
          }
        >
          View All Projects
        </p> */}
        </div>
        <div className={styles.sidebarMain_showmore}>Show More</div>
      </List>
      <Divider />
    </Box>
  );

  return (
    <div className={styles.sidebarMain}>
      <Drawer
        anchor={'right'}
        open={OpenAllLocations}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: '20002' }}
        PaperProps={{
          style: {
            width: isMobile ? '100%' : '400px',
            zIndex: '20001',
            borderTopLeftRadius: isMobile ? '' : '20px',
            borderBottomLeftRadius: isMobile ? '' : '20px',
          },
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}
