const DiagonalArrowSvg2 = ({ color = '#363352', width = 24, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill={color}
    >
      <path
        d="M1 9L9 1M9 1V8.68M9 1H1.32"
        stroke="#FEFEFF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DiagonalArrowSvg2;
